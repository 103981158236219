import React, { useRef, useState, useEffect, useCallback} from 'react';
import Data from '../../data/content.json'
import classes from './VideoSection.module.scss'

const VideoSection = ({sourceAdd}) => {
   
    const video = useRef();

    useEffect(() => {
        
        if(sourceAdd === true){
        video.current.play();
        }else{
            video.current.pause();
        }
    },[sourceAdd, video.current])


  return (
    <div  className={`container-fluid ${classes.VideoSection}`}>
        <video ref={video} width="100%" height="100%" autoPlay webkit-playsinline="true" muted="muted"
        playsInline={true}>
            {/* <source src={`${window.location}assets/videos/${Data.videoSection.src}`} type="video/mp4"/> */}
            <source src={`${Data.videoSection.src}`} type="video/mp4"/>
			Your browser does not support the video tag.
		</video> 
    </div>
  
  )
}

export default VideoSection