import React, { useRef, useEffect } from 'react';
import classes from './Hero.module.scss';

import { CSSPlugin } from 'gsap/CSSPlugin'
import gsap,{TweenMax, Power3} from 'gsap/gsap-core';

// import Header    from '../../share/Header/Header'

import Data from '../../data/content.json'

gsap.registerPlugin(CSSPlugin)

const Hero = ({menuCallBack}) => {

  const title = Data.hero.title;
  const headline = Data.hero.headline;

  const titleArr = [];
  const headlineArr = [];

  const titleSplit = title.split(" ");
  const headlineSplit = headline.split(" ");
  titleArr.push(titleSplit);
  headlineArr.push(headlineSplit);

  const titleRef = useRef([]);
  const headlineRef = useRef([]);

  const finalTitle = titleSplit.map((val, index) => (
    <h1 className={classes.Hero__title} key={index} ref={el => titleRef.current[index] = el}>{val}</h1>
  ))

  const finalHeadline = headlineSplit.map((val, index) => (
    <h1 className={classes.Hero__headline} key={index} ref={el => headlineRef.current[index] = el} dangerouslySetInnerHTML={{__html: val  }}></h1>
    // <h1 className={classes.Hero__headline} key={index} ref={el => headlineRef.current[index] = el}>{val}</h1>
  ))

  const iPadMediaQ = window.matchMedia("(min-width: 1200px)");
    
  useEffect(() => {
    TweenMax.set([titleRef.current, headlineRef.current],{opacity:0, y:-5});
    if(iPadMediaQ.matches){
      [titleRef.current, headlineRef.current].sort(function(){
            return 0.5 - Math.random()});
    }

    TweenMax.staggerTo( titleRef.current, 0.25, {
      delay:0.5, opacity: 1, y:0, ease: Power3.easeOut
      }, 0.1);

      TweenMax.staggerTo(headlineRef.current, 0.25, {
        delay: 1, opacity: 1, y:0, ease: Power3.easeOut
    }, 0.1);
  },[iPadMediaQ.matches]);


    return(
        <>
        {/* <Header content={Data.header} menuCallBack={menuCallBack}/> */}
        <div className={`row ${classes.Hero}`}>

          <div className={`col-lg-12 col-xl-12 ${classes.Hero__container}`}>
            <div className={classes.Hero__wrap}>
              <div>
                {finalTitle}
              </div>
              <div>
                {finalHeadline}
              </div>
            </div>
          </div>
        </div>
     
       </>
  )
}

export default Hero;


