import React from 'react'

import { getFileName } from '../../helpers/FileCaching';
import classes from './DownArrow.module.scss'

const downArrow = require.context('../../images/DownArrow', true, /.*\.(gif|png|jpe?g|svg)$/i);

const DownArrow = ({ onClickHandlerCallback }) => {
  
  return (
    <div className={classes.downArrowWrapper}>
          <img alt="arrow down" onClick={onClickHandlerCallback} className={classes.downArrowWrapper__img} src={getFileName(downArrow, "arrow-down.png" )} />
    </div>
  )
}

export default DownArrow