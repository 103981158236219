import React, { useEffect, useState }  from 'react'
import classes from './ThinkInsideTheBox.module.scss'

const ThinkInsideTheBox = ({ content}) => {
  const [isWindow, setIsWindow] = useState(false);

  useEffect(() => {
    if (navigator.appVersion.indexOf("Win") !== -1){
      setIsWindow(true) 
    }else{
      setIsWindow(false) 
    }
  },[ isWindow]);

  return (
    <>
      <div className={classes.thinkInsideTheBox}>
        {/* <div className="container-fluid"> */}
          <div className={`row ${classes.thinkInsideTheBox__content}`}>
            <div className={`col-md-12 col-lg-6 col-xl-5 ${classes.thinkInsideTheBox__aboutTitle}`}>
              <h2 id="about">{content.title}</h2>
            </div>

            <div className="col-md-12 col-lg-6 col-xl-6">
              <p className={`${classes.thinkInsideTheBox__description} ${ isWindow ? classes.thinkInsideTheBox__descriptionWin : "" }`} dangerouslySetInnerHTML={{__html: content.description  }} ></p>
            </div>
     
          </div>
        
        {/* </div> */}

      </div>
      
    </>
  )
}

export default ThinkInsideTheBox