import React, { useEffect, useState }  from 'react'
import MediaQuery from 'react-responsive';
import classes from './Header.module.scss';
import { getFileName } from '../../helpers/FileCaching';

// import MenuMobile from './MenuMobile/MenuMobile'

const headerImages = require.context('../../images/Header', true, /.*\.(gif|png|jpe?g|svg)$/i);

const Header = ({ content, menuCallBack }) => {

  const [isOpen,setIsOpen] = useState(false);
  const [isWindow, setIsWindow] = useState(false);

  function clickMenu() {
    if (!isOpen) setIsOpen(true);
    else setIsOpen(false);
  }

  function closeMenuOnClick(i){
    menuCallBack(i);
    setIsOpen(false)
  }

  function MenuItem() {
		
		return (
			<div className={`${classes.MenuItem} ` + (isOpen ? `${classes.MenuItem__menuOpen}` : ``)}>
			
				<nav className={`${classes.MenuItem__menuContent } ${classes.MenuItem__menuContentOpen} ${classes.menu__option_list} `
					
				}>
					<ul>
						{content.menu.map((v, i) => (
              <li  key={i} className={`${classes.header__menu__item} 
                ${ v.title === "member login" ? classes.header__menu__item_member : "" }
                ${ isWindow ? classes.header__menu__item__win : "" }`}>
								<span className={classes.MenuItem__title} onClick={ i === 3 ? () => window.open(v.url , "_blank") : () => closeMenuOnClick(i) }>
                    {v.title}
								</span>
							</li>
						))}
					</ul>
				</nav>
			</div>
		)
  }
  
  useEffect(() => {
    if (navigator.appVersion.indexOf("Win") !== -1){
      setIsWindow(true) 
    }else{
      setIsWindow(false) 
    }
  },[ isWindow]);
  
  return (
    <>
      <header className={classes.header}>
        <nav>
          <ul className={`d-flex justify-content-between  ${classes.navbar}`}>
            <li>
              <a href="/" className={classes.navbar__logo}>
                <h1 className={classes.header__logo} style={
                    {
                        backgroundImage: 'url(' + getFileName(headerImages, content.logo) + ')'
                    }
               }>squintbox</h1>
              </a>
            </li>

            <li>
            <MediaQuery minWidth={classes.screenLgMax}>
              <ul className={`${classes.header__menu} d-flex align-items-baseline`}>
                { content.menu.map( (v, i) => {
                  return (
                    <li key={i} className={`${classes.header__menu__item} 
                      ${ v.title === "member login" ? classes.header__menu__item_member : "" }
                      ${ isWindow ? classes.header__menu__item__win : "" }`}>
                      <span className={`${classes.header__menu__item__link}   `}
                      //  href={ongoToTop(v, i) }
                        // onClick={ () => menuCallBack(i) }
                        onClick={ i === 3 ? () => window.open(v.url , "_blank") : () => menuCallBack(i) }
                        name={v.url}
                        
                      >
                        {v.title} </span>
                      {/* <a className={`${classes.header__menu__item__link}   `} href={v.internal ?  `#${v.url}` : v.url } name={v.url}> {v.title} </a> */}
                    </li>
                  )
                })}
              </ul>
              </MediaQuery>
              <MediaQuery maxWidth={classes.screenLg}>
                <nav className={classes.header__navMob}>
                  <div className={`${classes.header__hamburger} ` + (isOpen ? `${classes.header__hamburgerOpen}` : ``)} onClick = {clickMenu} >
                      <span></span>
                      <span></span>
                      <span></span>
                  </div>
                </nav>
                { isOpen && <MenuItem/>}
              </MediaQuery>

            </li>
            
          </ul>
        
        </nav>
     
  
      </header>
    </>

  )
  
}

export default Header