import React, { useState, useEffect } from 'react';
//components
import Hero              from './components/Hero/Hero'
import DownArrow from './components/DownArrow/DownArrow'

import Header            from './share/Header/Header'
import ThinkInsideTheBox from './components/ThinkInsideTheBox/ThinkInsideTheBox';
import VideoSection from './components/VideoSection/VideoSection';
import GetInTouch        from './components/GetInTouch/GetInTouch'
import Data from './data/content.json'

import ReactFullpage from '@fullpage/react-fullpage';


const App = ({menuCallBack}) => {
  let [fullPageAPIState, setFullPageAPIState] = useState(null)
  let [destinationVar, setDestinationVar] = useState()
  let [activeSrc, setActiveSrc] = useState(false)

  useEffect(() => {
    if(fullPageAPIState){
      fullPageAPIState.moveTo(1)
    }
  },[fullPageAPIState])

  // function onLeave(origin, destination, direction) {
  //   console.log("Leaving section " + origin.index);
  // }
  function afterLoad(origin, destination, direction) {
    // console.log("After load: " + destination.index);
    setDestinationVar(destination.index);
  }
  
  useEffect(() => {
    console.log("PUSH DATA TO DATA LAYER", {
      'event':'page_view',
      'page_category':'Homepage',
      'page_name':'Site - Home', //Site - Home, Site Login
      'date_time':new Date().toLocaleString()
    })
    window.dataLayer.push({
      'event':'page_view',
      'page_category':'Homepage',
      'page_name':'Site - Home', //Site - Home, Site Login
      'date_time':new Date().toLocaleString()
    });
  }, [])

  useEffect(() => {
    if(destinationVar === 2){
      setActiveSrc(true)
    }else{
      setActiveSrc(false)
    }
  },[destinationVar, activeSrc])
  

  return (
    <div className="App">
      <div className="container-fluid">
   
        <Header content={Data.header} menuCallBack = {(goTo) => fullPageAPIState.moveTo(goTo+1)} /> 
        <ReactFullpage
          scrollOverflow={true}
          licenseKey={"662A4A4D-A778442B-91B7CEFC-00B5C29A"}
          // onLeave={onLeave}
          afterLoad={afterLoad}
          render={({ state, fullpageApi }) => {
          setFullPageAPIState(fullpageApi)
       
        
            return(
              <>
                <ReactFullpage.Wrapper>
                  <div className="section">
                    <Hero menuCallBack = {(goTo) => fullpageApi.moveTo(goTo+1)}/>
                    <DownArrow onClickHandlerCallback={() => fullpageApi.moveSectionDown()}/>
                  </div>
                  <div className="section">
                    <ThinkInsideTheBox onClickHandlerCallback={() => fullpageApi.moveSectionDown()} content={Data.thinkInsideTheBox}/>
                    <DownArrow onClickHandlerCallback={() => fullpageApi.moveSectionDown()}/>
                  </div>
                  <div className="section">
                    <VideoSection sourceAdd={activeSrc} />
                    {/* <DownArrow onClickHandlerCallback={() => fullpageApi.moveSectionDown()}/> */}
                  </div>
                  <div className="section">
                    <GetInTouch />
                  </div>
                </ReactFullpage.Wrapper>
              </>
            )
          }}
        />
      </div>
    </div>
  );
}




export default App;
